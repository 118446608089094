<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/tracker/detail/${uuid}`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-row>
      <b-col class="my-2">
        <b-button variant="primary" :to="`/groups/${uuid}/add`" class="float-right">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span  class="align-middle">{{$t("Labels.Add")}}</span>
        </b-button>
      </b-col>
    </b-row>
    <div id="data-list-list-view" class="data-list-container mt-2 px-">
      <b-table hover :fields="fields" :items="response" :responsive="true" tbody-tr-class="shadow-sm" table-variant="light">
            <template #cell(nameavatar)="item">
              <avatarItem :value="item.item.name" :description="$t('Labels.Name')" :img="item.item.avatar" class="mt-1"/>
            </template>
            <template #cell(description)="item">
              <listItem :value="item.value" :description="$t('Labels.Description')" img="card-text" class="mt-1"/>
            </template>
            <template #cell(uuiduser_group)="item">
              <b-button variant="primary" class="btn-icon rounded-circle btn-option mt-1" :to="`/groups/map/${uuid}/${item.item.uuid}`" :title="$t('Labels.Map')" v-b-tooltip.hover="{ variant: 'primary', placement: 'top' }">
                <feather-icon icon="MapIcon" :width="18" :height="18"/>
              </b-button>
              <b-button variant="primary" class="btn-icon rounded-circle btn-option mt-1" :to="`/groups/members/${uuid}/${item.item.uuid}`" :title="$t('Labels.Members')" v-b-tooltip.hover="{ variant: 'primary', placement: 'top' }">
                <feather-icon icon="UsersIcon"  :width="18" :height="18"/>
              </b-button>
              <b-button v-if="item.item.user_group[0].is_administrator" variant="primary" class="btn-icon rounded-circle btn-option mt-1" :to="`/groups/edit/${uuid}/${item.item.uuid}`" :title="$t('Labels.Edit')" v-b-tooltip.hover="{ variant: 'primary', placement: 'top' }">
                <feather-icon icon="EditIcon"  :width="18" :height="18"/>
              </b-button>
              <b-button v-if="item.item.user_group[0].is_administrator" variant="danger" class="btn-icon rounded-circle btn-option mt-1" @click="confirmDelete(item.item.uuid)" :title="$t('Labels.Delete')" v-b-tooltip.hover="{ variant: 'danger', placement: 'top' }">
                <feather-icon icon="TrashIcon"  :width="18" :height="18"/>
              </b-button>
              <b-button variant="danger" class="btn-icon rounded-circle  mt-1" @click="confirmExit(item.item.uuid)" :title="$t('Labels.Exit')" v-b-tooltip.hover="{ variant: 'danger', placement: 'top' }">
                <feather-icon icon="LogOutIcon"  :width="18" :height="18"/>
              </b-button>
            </template>
      </b-table>
    </div>
    <div v-if="response.length == 0" class="noData">{{$t('Messages.NoData')}}</div>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import avatarItem from '@core/spore-components/avatarItem/avatarItem.vue'
import {
  BButton,
  BRow,
  BCol,
  BTable
} from 'bootstrap-vue'
import listItem from '@core/spore-components/listItem/listItem.vue'

const DATA = {
  code: 200,
  data: {
    count: 2,
    rows: []
  },
  msg: 'Ok'
}

export default {
  components: {
    BButton,
    BRow,
    BCol,
    avatarItem,
    listItem,
    BTable
  },
  data () {
    return {
      response: [],
      DATA,
      uuid: this.$route.params.uuid,
      fields: [
        { key: 'nameavatar', label: this.$t('Labels.Name') },
        { key: 'description', label: this.$t('Labels.Description') },
        { key: 'uuiduser_group', label: '' }
      ]
    }
  },

  methods: {
    async getResponse () {
      // this.response = DATA.data.rows
      const params = {
        url: 'tracker_groups/myGroups',
        method: 'GET',
        params: {
          tracker: this.uuid
        }
      }
      await request(params).then(response => {
        this.response = response.data.data
      }).catch(() => {
        this.response = []
      })
    },
    confirmDelete (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmDeleteGroup'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_groups/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    },
    confirmExit (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmExit'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_groups/exit/${this.uuid}/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    }
  },

  created () {
    this.getResponse()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/ListGroups.scss";
</style>
